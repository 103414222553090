<template>
  <head>
    <title>掛け布団</title>
  </head>
  <aheader/>
  <img height="250" width="600" style="float: bottom;" src="@/assets/goodsleep_logo.jpg">
  <section class="section"  v-for="item, index in this.items" :key="index">
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <div class="error-message notification is-danger is-light" v-show="errorMsgList[index] != ''" v-html="errorMsgList[index]"></div>
        <div class="columns">
          <div class="column is-narrow">
            <div class="is-size-3-tablet is-size-4-mobile has-text-left-desktop">
              {{item.name}}
            </div>
            <div class="is-size-5-tablet is-size-6-mobile">
              {{itemSubtitles[item.name]}}
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3">
            <div class="image">
              <img :src="item.icon">
            </div>
            <p class="is-size-6">
              ※写真はイメージです。
            </p>
          </div>
          <div class="column is-9">
            <div class="content has-text-left" v-html="this.itemExplanation[item.name]">
            </div>
            <div class="columns is-desktop">
              <div class="column is-narrow">
                <p>商品のラインナップ</p>
                <template v-if="item.name != 'ぐっすりーぷ（まくら）'">
                  <table class="table is-bordered mx-auto">
                    <thead>
                      <tr>
                        <th>シングルロング</th>
                        <th>セミダブルロング</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>165,000円</td>
                        <td>220,000円</td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <table class="table is-bordered mx-auto">
                    <thead>
                      <tr>
                        <th>やわらかめ</th>
                        <th>ふつう</th>
                        <th>かため</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3">8,000円</td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>
            <div class="column is-narrow">
              <div>
                <div class="box">
                  <p class="mb-5">
                    購入する
                  </p>
                  <div class="columns">
                    <div class="column is-narrow">
                      <div class="select">
                        <select v-model="this.kinds[index]">
                          <option disabled value="">種類</option>
                          <option v-for="value, key in item.kindsList" :key="key">{{key}}</option>
                        </select>
                      </div>
                      <div class="mt-3">
                        <p v-if="itemValue(index) != undefined">
                          価格：{{itemValue(index)}}円
                        </p>
                      </div>
                    </div>
                    <div class="column is-3">
                      <button class="button is-info is-outlined" @click="addtoCart(index)">
                        <span>
                          カートに追加
                        </span>
                        <span class="icon">
                          <i class="fa-solid fa-cart-shopping"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <!--
                <template v-if="item.name == 'ぐっすりーぷ（敷ふとん）'">
                  <button class="button is-danger is-outlined" @click="pushPage('application')">
                    来店予約
                  </button>
                </template>
                <template v-else>
                  <div class="box">
                    <p class="mb-5">
                      購入する
                    </p>
                    <div class="columns">
                      <div class="column is-narrow">
                        <div class="select">
                          <select v-model="this.items[index].kind">
                            <option disabled value="">種類</option>
                            <option v-for="value, key in item.kindsList" :key="key">{{key}}</option>
                          </select>
                        </div>
                        <div class="mt-3">
                          <p v-if="itemValue(index) != undefined">
                            価格：{{itemValue(index)}}円
                          </p>
                        </div>
                      </div>
                      <div class="column is-3">
                        <button class="button is-info is-outlined" @click="addtoCart(index)">
                          <span>
                            カートに追加
                          </span>
                          <span class="icon">
                            <i class="fa-solid fa-cart-shopping"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                -->
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--
  <div class="block">
    <div class="content is-normal">
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <h1 class="title" style="text-align:left">掛け布団のラインナップ</h1>
          </div>
        </div>
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <div class="block" v-for="item, index in itemList" :key="index">
              <h3 style="text-align:left">
                <span class="icon-text mb-4">
                  <span>
                    {{index + 1}}. {{item.name}}（{{item.kind}}）
                  </span>
                  <span class="icon" @click="addtoCart(index); modalOn()">
                    <i class="fa-solid fa-cart-shopping"></i>
                  </span>
                </span>
              </h3>
              <div class="columns">
                <div class="column is-one-quarter is-offset-1">
                  <img class="image is-256x256" :src="item.icon">
                </div>
                <div class="column is-three-quarter">
                  <p class="is-size-5 mt-4">商品についての説明―商品の特徴やどのような人に向けた商品なのかの説明を入れる。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="block">
    <section class="hero is-halfhight is-light">
      <div class="hero-body">
        <div class="content is-normal">
          <h1>オンライン決済について</h1>
          <div class="bulma-clearfix-mixin">
            <img height="209" width="300" style="float: top;" src="kari3.jpg">
            <p>上のイラストは「工事中」みたいなお辞儀のイラストが良いかなと思います。
              <br/>こちらのホームページで掲載している商品はおぐら屋で取り扱っている商品の一部です。
              <br/>オンライン購入は店舗で一度購入した商品を再購入する際にご利用を検討してください。
              <br/>オンライン購入される際は店舗で商品を確認をお願いします。</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  -->
  <div class="modal" v-bind:class="modalClass">
    <div class="modal-background" @click="modalOn()"></div>
      <div class="modal-content">
        <div class="box">
          <section class="section">
            <p class="title">
              商品がカートに追加されました
            </p>
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <div class="level">
                  <div class="level-item">
                    <button class="button is-outlined has-text-gray-dark" @click="modalOn()">
                      買い物を続ける
                    </button>
                  </div>
                  <div class="level-item">
                    <button class="button is-info is-outlined" @click="pushPage('/cart')">
                      カートへ移動
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    <button class="modal-close is-large" aria-label="close" @click="modalOn()"></button>
  </div>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {item_id:"sVEA8YzoIp9q7csGh6Ty", name:"ぐっすりーぷ（敷ふとん）", icon:require("@/assets/shikibuton.jpg"), kind:"",
        kindsList:{"シングルロング":165000, "セミダブルロング":220000}, value:"", count:1},
        {item_id:"SaSyCxWMSIZmZLLi3Y51", name:"ぐっすりーぷ（掛ふとん）", icon:require("@/assets/kakebuton.jpg"), kind:"",
        kindsList:{"シングルロング":165000, "セミダブルロング":220000}, value:"", count:1},
        {item_id:"zVLih46FFnmso7m3EwK1", name:"ぐっすりーぷ（まくら）", icon:require("@/assets/makura.jpg"), kind:"",
        kindsList:{"やわらかめ":8800, "ふつう":8800, "かため":8800}, value:"", count:1},
      ],
      itemExplanation: {
        "ぐっすりーぷ（敷ふとん）":"<p>立っている時と同じ姿が理想的な寝姿の基本。体圧や体組成計の計測結果をもとに熟練の寝具指導士があなた専用の敷布団を調整します。面で支えた体圧を点から逃すことで一部の筋肉や骨に負担が集中することを回避します。<br>肩こりや腰の痛み、また妊娠後期の方は特に睡眠中にも身体の一部に負担がかかっていることも。敷布団をご自身の身体に合わせることは充実した１日を過ごす原動力になります。</p><p>※ぐっすりーぷ（敷ふとん）はオーダーメイドのため、店舗のみでの販売となります。</p>",
        "ぐっすりーぷ（掛ふとん）":"<p>羽毛をシルク真綿で包んだ掛けふとんです。この特殊な3層構造で、高い保温性を保ちつつ抜群の吸湿性・放湿性を発揮します。人間の肌の成分に近いといわれるシルク真綿。この天然繊維はアレルギーを起こし難いと言われ、またその柔軟性から生まれる肌添いの良さはあなたを心地よい眠りに誘います。</p>",
        "ぐっすりーぷ（まくら）":"<p>エリアごとに分けてつくる枕は質の高い睡眠には必需品。頚部を適切に支えることにより首や肩への負荷を軽減し、また、スムーズな寝返りを促すことができます。パイプやポリエステル綿の中材で硬さや肌触りの調整をします。</p>"
        //"ぐっすりーぷ（掛ふとん）":"<p>商品サイズ：シングルロング（165,000円） / セミダブルロング（220,000円）</p><p>羽毛をシルク真綿で包んだ掛けふとんです。この特殊な3層構造で、高い保温性を保ちつつ抜群の吸湿性・放湿性を発揮します。人間の肌の成分に近いといわれるシルク真綿。この天然繊維はアレルギーを起こし難いと言われ、またその柔軟性から生まれる肌添いの良さはあなたを心地よい眠りに誘います。</p>",
        //"ぐっすりーぷ（敷ふとん）":"<p>商品サイズ：シングルロング（165,000円） / セミダブルロング（220,000円）</p><p>立っている時と同じ姿が理想的な寝姿の基本。体圧や体組成計の計測結果をもとに熟練の寝具指導士があなた専用の敷布団を調整します。面で支えた体圧を点から逃すことで一部の筋肉や骨に負担が集中することを回避します。<br>肩こりや腰の痛み、また妊娠後期の方は特に睡眠中にも身体の一部に負担がかかっていることも。敷布団をご自身の身体に合わせることは充実した１日を過ごす原動力になります。</p>",
        //"ぐっすりーぷ（まくら）":"<p>種類： やわらかめ / ふつう / かため（一律8,000円）</p><p>エリアごとに分けてつくる枕は質の高い睡眠には必需品。頚部を適切に支えることにより首や肩への負荷を軽減し、また、スムーズな寝返りを促すことができます。パイプやポリエステル綿の中材で硬さや肌触りの調整をします。</p>"
      },
      itemSubtitles: {
        "ぐっすりーぷ（敷ふとん）":"体圧分散健康敷ふとん",
        "ぐっすりーぷ（掛ふとん）":"羽毛入りシルク真綿ふとん",
        "ぐっすりーぷ（まくら）":"オーダーまくら"
      },
      modalClass: "",
      kinds: ["", "", ""],
      valueList:["", "", ""],
      errorMsgList:["", "", ""]
    }
  },
  mounted() {
    this.scrollTop()
  },
  computed: {
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    itemValue(index) {
      let kind = this.kinds[index]
      this.valueList[index] = this.items[index].kindsList[kind]
      //console.log(this.valueList)
      if (this.valueList[index] != undefined) {
        return this.valueList[index].toString().replace(/\B(?=(\d{3})+$)/g, ',');
      }
    },
    addtoCart(index) {
      //console.log(this.kinds)
      this.errorMsgList[index] = ""
      if (this.kinds[index] == "") {
        this.errorMsgList[index] = "種類を選んでください"
      }
      if (this.errorMsgList[index] == "") {
        //console.log("count", this.items[index].count)
        const itemKind = this.kinds[index]
        const Item = this.items[index]
        //console.log("Item", Item)
        Item.kind = itemKind
        Item.value = Item.kindsList[itemKind]
        //console.log("Item_nex", Item)
        this.$store.commit("addtoCart", Item)
        this.modalOn()
      }
    },
    pushPage(page) {
      //this.setKind()
      this.$router.push(page);
    },
    setKind() {
      this.$store.commit('setKind', [])
    },
    modalOn() {
      if (this.modalClass == "is-active") {
        this.modalClass = ""
      } else {
        this.modalClass = "is-active"
      }
    },
    addErrMsg(msg) {
      if (this.errorMsg != "") this.errorMsg += "<br/>";
      this.errorMsg = this.errorMsg + msg;
    }
  }
}
</script>
<style scoped>
.icon {
  cursor: pointer;
}

.icon:hover {
  opacity: 0.8
}

.box-width {
  width:222px
}
</style>
